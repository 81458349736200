import React from "react";


function Description() {
  return (
    <section className='description'>

      <h2 id="advantages">Преимущества <strong>NoNaMe Min</strong> 118w*2</h2>
      <p className='text'>  О самокате <strong>NoNaMe</strong> отзывы самые хорошие по многим причинам. Среди его преимуществ:</p>
      <ul>
        <li><strong>NoNaMe</strong>, можно поставить сиденье. </li>
        <li>Так же в задней части самоката есть упор для ноги, он будет удобен, если вы предпочитаете передвигаться стоя на самокате.</li>
        <li> Самокат оснащен одним зарядным устройствам на 3A.</li>
        <li>Мотор-колеса на данной модели (спереди и сзади) имеют мощность 118w*2 (236w).</li>
        <li>Огромная мощность и плавный старт</li>
        <li>Новая и мощная амортизационная система</li>
        <li>Покрышки стоят камерные.</li>
        <li>Можно поставить 11-14 дюймовые покрышки.</li>
        <li>Самокат оснащен двумя контроллерами на 25A.</li>
        <li>Руль не имеет люфт. Нескладной.</li>
        <li>Подвеска рычажного типа + регулируемые амортизаторы.</li>
        <li>Самокат имеет мощную LED фару, а так же дополнительные диоды освещения, находящиеся в деке и ферме электросамоката.</li>
        <li>На одном заряде, <strong>NoNaMe</strong> проедет до 65км.</li>
        <li>Настройку режима езды Вы сможете произвести в компьютере самоката.</li>
        <li>Самокат имеет АКВА-ЗАЩИТУ. Контролеры находятся в компаунде.</li>
        <li>Батарея самоката в водонепроницаемом кейсе.</li>
        <li>Вся проводка на АКВА переходниках и сведена к минимуму.</li>
      </ul>
      <p className='text'></p>
      <p className='text'></p>
      <p className='text'></p>
      <p className='text'></p>
      <p className='text'></p>

    </section>
  );
}


export default Description;