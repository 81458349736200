import React from "react";


function Header() {
  return (
    <header className="App-header">

      <h1 className='title' itemprop="name">Электросамокат NoNaMe.</h1>

      <ul className="list-title">
        <li> <a href='#description'><strong>Описание</strong></a> </li>
        <li> <a href='#advantages'><strong>Преимущества</strong></a> </li>
        <li> <a href='#characteristics'><strong>Характеристика</strong></a> </li>
        <li> <a href='#equipment'><strong>Комплектация</strong></a> </li>
        <li> <a href='#buy'><strong>Покупка</strong></a> </li>
        <li> <a href='#min-characteristics'><strong>Основные характеристики</strong></a> </li>
      </ul>

      <div className="image">
        <picture className="image__picture">
          <source media="(min-width: 1150px)" srcSet="images/noname1.png 1x, images/noname1.png 2x" />
          <source media="(min-width: 768px)" srcSet="images/noname1.png 1x, images/noname1.png 2x" />
          <img className="image__img" src="images/noname1.png" srcSet="images/noname1.png 2x" alt="Электросамокат NoNaMe" itemprop="image" />
        </picture>
      </div>

      <section id="description" >
        <h2>
          Описание Электросамоката Нонаме
        </h2>
        <p className='text' itemprop="description">
          Электросамокат <strong>NoNaMe</strong> – новинка 2023 года. Самый мощный, быстрый самокат в мире. Мощные мотор-колеса. 
          Надежная рычажная подвеска. Пневматические амортизаторы. 
          Безлюфтовый руль и другие преимущества обеспечили ему высокую популярность. Все характеристики <strong>NoNaMe</strong> говорят о том,
          что эта модель является одним из мощных, но в то же время доступных по цене электросамокатов на рынке.
          <br></br>
          <br></br>
          Электросамокат <strong>NoNaMe</strong> – произведён в России.
          <br></br>
          Сделан для российских дорог.
          <br></br>
          <strong className='text__red'>Не требует водительское удостоверения</strong>
        </p>
      </section>
    </header>
  );
}


export default Header;