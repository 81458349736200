import React from "react";


function EquipmentBig() {
  return (
    <div>

    </div>
  );
}


export default EquipmentBig;