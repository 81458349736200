import React from "react";
import DescriptionMax from "../../app/description/descriptionMax.jsx";
import CharacteristicsMax from "../../app/characteristics/characteristicsMax.jsx";
import EquipmentMax from "../../app/equipment/equipmentMax.jsx";
import BuyMax from "../../app/buy/buyMax.jsx";
import EquipmentBigMax from "../../app/equipmentBig/equipmentBigMax.jsx";
import CharacteristicsBigMax from "../../app/characteristicsBig/characteristicsBigMax.jsx";

function Max() {
  return (
    <div>
      <DescriptionMax />
      <CharacteristicsMax />
      <EquipmentMax />
      <BuyMax />
      <EquipmentBigMax />
      <CharacteristicsBigMax />
    </div>
  )
}


export default Max;