import React from "react";
import Description from "../../app/description/description.jsx";
import Characteristics from "../../app/characteristics/characteristics.jsx";
import Equipment from "../../app/equipment/equipment.jsx";
import Buy from "../../app/buy/buy.jsx";
import EquipmentBig from "../../app/equipmentBig/equipmentBig.jsx";
import CharacteristicsBig from "../../app/characteristicsBig/characteristicsBig.jsx";

function Min() {
  return (
    <div>
      <Description />
      <Characteristics />
      <Equipment />
      <Buy />
      <EquipmentBig />
      <CharacteristicsBig />
    </div>
  )
}


export default Min;