import React from "react";



function CharacteristicsBig() {
  return (
    <section className='characteristics'>
    <div>
      <h2 id="min-characteristics">Основные характеристики</h2>
      <ul className='ul-row'>
        <li className='row'>
          <div>Размер колес</div>
          <div>11"</div>
        </li>
        <li className='row'>
          <div>Скорость</div>
          <div>До 125 км/ч</div>
        </li>
        <li className='row'>
          <div>Расстояние</div>
          <div>До 400 км</div>
        </li>
        <li className='row'>
          <div>Вес райдера</div>
          <div>200 кг</div>
        </li>
        <li className='row'>
          <div>Мощность</div>
          <div>30000 Вт</div>
        </li>
        <li className='row'>
          <div>Время зарядки</div>
          <div>10 часов</div>
        </li>
        <li className='row'>
          <div>Батарея</div>
          <div>Литиевая, 72v 150ah</div>
        </li>
        <li className='row'>
          <div>Амортизация</div>
          <div>Двухподвес</div>
        </li>
        <li className='row'>
          <div>Рама</div>
          <div>Алюминиевая</div>
        </li>
        <li className='row'>
          <div>Тормоза</div>
          <div>Дисковые гидравлические + электронные</div>
        </li>
        <li className='row'>
          <div>Вес</div>
          <div>В зависимости от комплектации</div>
        </li>
      </ul>

      <h3>Дополнительно</h3>
      <ul>
        <li className='row'>
          <div>Дисплей</div>
          <div>Есть, цветной</div>
        </li>
        <li className='row'>
          <div>Фары</div>
          <div>Передняя Arctic</div>
        </li>
        <li className='row'>
          <div>Сидение</div>
          <div>Есть</div>
        </li>
        <li className='row'>
          <div>Подножка</div>
          <div>Есть</div>
        </li>
        <li className='row'>
          <div>Приложение APP</div>
          <div>Есть</div>
        </li>
      </ul>
    </div>
  </section>
  );
}


export default CharacteristicsBig;