import React from "react";



function Buy() {
  return (

    <section id="buy">

      <h2>ПОКУПКА САМОКАТА <strong>NoNaMe</strong></h2>
      <p>
        В нашем магазине оригинальный самокат <strong>NoNaMe</strong> купить можно по минимальной цене, от производителя. При этом на самокат дается гарантия до 3-х лет.
        Данная модель является прямым конкурентом любого электросамоката. Покупайте отличный современный электротранспорт для бездорожья и города!
      </p>

      <div itemprop="offers" itemscope itemtype="https://schema.org/Offer">
        <meta itemprop="price" content="На заказ" />
        <meta itemprop="priceCurrency" content="RUB" />

        <p>Самокат делается на заказ.</p>

      </div>
    </section>
  );
}


export default Buy;