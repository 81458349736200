import React from "react";


function Characteristics() {
  return (
    <section id="characteristics">
    <h2>ХАРАКТЕРИСТИКИ ЭЛЕКТРОСАМОКАТА <strong>NoNaMe</strong>:</h2>

    <ul>
      <li>Полный привод.</li>
      <li>Мощность мотор-колеса 118w (суммарно 236w), диаметр 10дюймов.</li>
      <li>Магниты 5см.</li>
      <li>Контроллер 52V 25A.</li>
      <li>Максимальная скорость до 25км/час.</li>
      <li>Максимальное расстояние на одном заряде до 65 км.</li>
      <li>Вес - <i>в зависимости от комплектации.</i></li>
      <li>Грузоподъёмность 200 кг.</li>
      <li>Аква-защита с завода.</li>
      <li>Батарея 52V25A MADE IN TAIWAN имеет полную сертификацию ГОСТ, а так же батарея имеет балансир.</li>
      <li>Безлюфтовая рычажная подвеска + регулируемые амортизаторы воздушного и масляного типа.</li>
      <li>2 выхода для зарядного устройства, одно зарядных устройства.</li>
      <li>Умная BMS плата с автоматическим предохранителем от перезагрузки батареи, двигателя.</li>
      <li>Руль безлюфтовой, цельный.</li>
      <li>Сиденье с внутренним амортизатором.</li>
      <li>Задний упор.</li>
      <li>Алюминиевая рама.</li>
    </ul>
  </section>
  );
}


export default Characteristics;