import React from "react";


function Equipment() {
  return (
    <section id="equipment">
    <h2>Комплектация:</h2>
    <ul>
      <li>самокат 1шт.</li>
      {/* <li>документы + инструкция 1 шт.</li> */}
      <li>набор инструментов 1 комплект</li>
      <li>зарядное устройство 1шт.</li>
      <li>сиденье</li>
    </ul>
  </section>
  );
}


export default Equipment;