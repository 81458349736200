import React from "react";
import Header from "./app/header/header.jsx";

// import Gallery from "./app/gallery/gallery.jsx";

import Min from "./app/Min/Min.jsx";
import Max from "./app/Max/Max.jsx";


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';


function App() {
  return (

    <div className="App" itemscope itemtype="https://schema.org/Product">
      <Header />

      <BrowserRouter>

        <div className="btns">
          <Link to="/">Версия Min </Link>
          <Link to="/Max">Версия Max </Link>
        </div>


        <main className="main">
          <Routes>
            <Route path="/" element={<Min />} />
            <Route path="/Max" element={<Max />} />
          </Routes>
        </main>
      </BrowserRouter>


      <footer className="footer">

      </footer>

    </div>
  );
}




export default App;
